// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Hamburgermenu from "../../blocks/hamburgermenu/src/Hamburgermenu";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Admanager from "../../blocks/admanager/src/Admanager";
import Googleadsenseintegration from "../../blocks/googleadsenseintegration/src/Googleadsenseintegration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfaffiliateaggregatorapi2 from "../../blocks/cfaffiliateaggregatorapi2/src/Cfaffiliateaggregatorapi2";
import Affiliateurl from "../../blocks/affiliateurl/src/Affiliateurl";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Crcustomtemplatesfillableforms2 from "../../blocks/crcustomtemplatesfillableforms2/src/Crcustomtemplatesfillableforms2";
import OfflineBrowsing from "../../blocks/offlinebrowsing/src/OfflineBrowsing";
import Sorting from "../../blocks/sorting/src/Sorting";
import Crshopsearchupdate from "../../blocks/crshopsearchupdate/src/Crshopsearchupdate";
import Crupdatedfilterfunctionanddesign2 from "../../blocks/crupdatedfilterfunctionanddesign2/src/Crupdatedfilterfunctionanddesign2";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Analytics from "../../blocks/analytics/src/Analytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";



const routeMap = {
Hamburgermenu:{
 component:Hamburgermenu,
path:"/Hamburgermenu"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
Documentstorage:{
 component:Documentstorage,
path:"/Documentstorage"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Admanager:{
 component:Admanager,
path:"/Admanager"},
Googleadsenseintegration:{
 component:Googleadsenseintegration,
path:"/Googleadsenseintegration"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfaffiliateaggregatorapi2:{
 component:Cfaffiliateaggregatorapi2,
path:"/Cfaffiliateaggregatorapi2"},
Affiliateurl:{
 component:Affiliateurl,
path:"/Affiliateurl"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Crcustomtemplatesfillableforms2:{
 component:Crcustomtemplatesfillableforms2,
path:"/Crcustomtemplatesfillableforms2"},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Crshopsearchupdate:{
 component:Crshopsearchupdate,
path:"/Crshopsearchupdate"},
Crupdatedfilterfunctionanddesign2:{
 component:Crupdatedfilterfunctionanddesign2,
path:"/Crupdatedfilterfunctionanddesign2"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;